@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;700&display=swap");

body {
  background: #f55;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timer-container {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 5px 12px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 10px;
}

p {
  font-size: 60pt;
  font-weight: normal;
  font-family: Rajdhani, Arial, Helvetica, sans-serif;
  color: #e88;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.title {
  font-size: 10;
  color: #e88;
  font-weight: normal;
  font-family: Ubuntu, Arial, Helvetica, sans-serif;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  display: flex;
  height: 44;
  background-color: #e44;
  text-align: center;
  font-family: Ubuntu, Arial, Helvetica, sans-serif;
  font-size: 15pt;
  font-weight: 500;
  color: #fff;
  border-width: 0px;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 6px;
  margin-top: 6px;
  box-shadow: 0.5px 0.5px 5px 1px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.button:hover {
  background-color: rgb(247, 179, 179);
}

.button > p {
  display: none;
}

.center-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.tomatoes-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  width: 100%;
  max-width: 300px;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px;
}

.tomato {
  height: 30px;
}
